.MuiCard-root.add-image-card {
    max-width: 140px;
}

.add-image-card .dnd-file {
    height: 120px;
    width: 110px;
    padding: 0 5px;
    margin: 10px auto;
    text-align: center;
    cursor: pointer;
}

.light .add-image-card .dnd-file {
    border: 1px solid rgba(0, 0, 0, 0.08);
    background-color: #F0F8FF;
}

.dark .add-image-card .dnd-file {
    border: 1px solid rgba(255, 255, 255, 0.08);
    background-color: #2A2A2A;
}
