.user-role-form .roles {
    width: 100%;
}

.user-role-form .add-role {
    margin-top: 5px;
}

.user-role-form .add-role svg {
    margin-right: 5px;
}
