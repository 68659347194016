.edit-account-page .card {
  width: 320px;
  vertical-align: top;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.edit-account-page .join-code {
  margin-top: 30px;
}
