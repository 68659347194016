.dark .status {
    font-weight: bold;
}

.dark .status.Active, .dark .status.Success {
    color: #54CD54;
}

.dark .status.Disabled, .dark .status.Failure, .dark .status.Draft {
    color: #EF7676;
}
