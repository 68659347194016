.footer {
    height: 56px;
}

.footer .bottom-nav {
    top: auto;
    bottom: 0;
}

.MuiSnackbar-root {
    bottom: 64px !important;
}

.MuiDrawer-root.left-nav .MuiPaper-root,
.MuiDrawer-root.left-nav .MuiPaper-root span,
.MuiDrawer-root.left-nav .MuiPaper-root div {
    width: 56px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.MuiDrawer-root.left-nav .MuiPaper-root .MuiBadge-root {
    width: 56px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MuiDrawer-root.left-nav .MuiPaper-root .nav-button {
    width: 56px;
    min-height: 80px;
    max-height: 168px;
    padding: 12px 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.MuiDrawer-root.left-nav .MuiPaper-root .nav-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.landscape.short .app-styles .content,
.MuiModal-root .content {
    margin: 20px 40px;
    width: calc(100% - 56px);
    padding-left: 32px;
}
