.owners-form .owners {
    width: 100%;
}

.owners-form .add-owner {
    margin-top: 5px;
}

.owners-form .add-owner svg {
    margin-right: 5px;
}
