.app-redacted-form .fields {
    width: 100%;
}

.app-redacted-form .add-field {
    margin-top: 5px;
}

.app-redacted-form .add-field svg {
    margin-right: 5px;
}
